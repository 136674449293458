import {
  TAnimalDetailsGenderField,
  TApplicationSADocPacketGet,
  TApplicationSADocPacketGetUI,
  TEditingWeightField,
  TEditingWeightFieldUi,
} from 'data/types/Applications.types';
import {
  TAuthConverter,
  TConvertor,
  TCreateConverter,
  TDeleteConverter,
  TFetchConverter,
} from 'data/types/Converter.types';
import {
  EGender,
  EReproductive,
  EValueEditingStates,
  EVetRecordTypeEnum,
  TExpirationDateType,
} from 'data/types/GeneralTypes';
import {
  EConversionReasonDB,
  EConversionReasonUI,
} from 'data/datas/GenericData';
import {
  EAnimalActionDB,
  EAnimalActionUI,
  EAnimalStatusDB,
  EAnimalStatusUI,
  EAnimalTypes,
  EAnimalTypesUI,
  EAuthorizationStatus,
  EAuthorizationStatusUi,
  EConvertAnimalStatus,
  EConvertAnimalStatusUI,
  ENotBeingChargedReasonDB,
  ENotBeingChargedReasonUI,
  EResidenciesStatus,
  EResidenciesStatusUI,
  EResidencyStateDB,
  EResidencyStateUI,
  EVetRecordDateType,
  TAnimalApplication,
  TAnimalApplicationRelatedPropertyManagers,
  TAnimalApplicationRelatedPropertyManagersUi,
  TAnimalApplicationUi,
  TAnimalBasicInfo,
  TAnimalBasicInfoUi,
  TAnimalDB,
  TAnimalDetail,
  TAnimalDetailsGenderFieldUi,
  TAnimalDetailUi,
  TAnimalFollowUpDB,
  TAnimalFollowUpUI,
  TAnimalInfoDB,
  TAnimalInfoUI,
  TAnimalResidencyDB,
  TAnimalResidencyUI,
  TAnimalUI,
  TAnimalVetRecordCreate,
  TAnimalVetRecordCreateUi,
  TAnimalWarningVerificationDB,
  TAnimalWarningVerificationUI,
  TApproveAnimalTypeConvDB,
  TApproveAnimalTypeConvUI,
  TBlockedAction,
  TBlockedActionUI,
  TChangedRecords,
  TChargingStatusUpdateDB,
  TChargingStatusUpdateUI,
  TConversionType,
  TConversionTypeUI,
  TFollowUpItemDB,
  TFollowUpItemUI,
  TMarkAsDiscoverdApplicantDB,
  TMarkAsDiscoverdApplicantUI,
  TMarkAsDiscoverdDB,
  TMarkAsDiscoverdUI,
  TMarkAsPNCDB,
  TMarkAsPNCUI,
  TNotesDB,
  TNotesUI,
  TRejectAnimalTypeConvDB,
  TRejectAnimalTypeConvUI,
  TRemoveApplicantDiscoveredDB,
  TRemoveApplicantDiscoveredUI,
  TResidencies,
  TResidenciesUi,
  TTenantDetail,
  TTenantDetailUi,
  TTenantInfoDB,
  TTenantInfoUI,
  TUpdateAnimalTypeDB,
  TUpdateAnimalTypeUI,
  TVetRecord,
  TVetRecordDocuments,
  TVetRecordDocumentsUI,
  TVetRecordExpirationUpdate,
  TVetRecordExpirationUpdateUI,
  TVetRecordTypeNameUpdate,
  TVetRecordTypeNameUpdateUI,
  TVetRecordUI,
} from 'data/types/Animal.types';
import { trainerInfoConvertor } from 'data/convertors/AnimalTraining.convertors';
import { EDocPacketStatusUi } from 'data/types/DocPacket.types';
import {
  EVetRecordStatus,
  EVetRecordStatusUi,
  TApplicationVetRecords,
  TApplicationVetRecordsUI,
} from 'data/types/VetRecord.types';
import { b64toBlob } from 'api/sevices/fileServices';
import {
  animalChargingStatusConverter,
  applicationStatusConvertor,
  defaultDateFormat,
  failsConvertor,
  fileDataTypeConverter,
  formattedDate,
  genderEnumConvertor,
  getFirstLetters,
  propertyInfoConverter,
  propertyManagementInfoConverter,
  statusChangeInfoConverter,
  statusInfoWithResourceConverter,
  verificationStatusConverter,
} from './General.converters';
import {
  docPacketStatusConverter,
  getHPDocPacketsConvertor,
} from './DocPacket.convertors';

import { tagConverter, tenantTagConverter } from './Tags.converters';

export const vetRecordStatusConverter: TConvertor<
  EVetRecordStatusUi,
  EVetRecordStatus
> = {
  fromDb: data => {
    const statuses: {
      [key in EVetRecordStatus]: EVetRecordStatusUi;
    } = {
      [EVetRecordStatus.NONE]: EVetRecordStatusUi.NONE,
      [EVetRecordStatus.VERIFIED]: EVetRecordStatusUi.VERIFIED,
    };
    return statuses[data];
  },
  toDb: data =>
    EVetRecordStatus[EVetRecordStatusUi[data] as keyof typeof EVetRecordStatus],
};

export const reproductiveConvertor: TFetchConverter<EReproductive, EGender> = {
  fromDb: data => {
    const reproduction: {
      [key in EGender]: EReproductive;
    } = {
      [EGender.FEMALE]: EReproductive.SPAYED,
      [EGender.MALE]: EReproductive.NEUTERED,
    };
    return reproduction[data];
  },
};

export const animalTypesConvertor: TConvertor<EAnimalTypesUI, EAnimalTypes> = {
  fromDb: data => {
    return EAnimalTypesUI[EAnimalTypes[data] as keyof typeof EAnimalTypesUI];
  },
  toDb: data => {
    return EAnimalTypes[EAnimalTypesUI[data] as keyof typeof EAnimalTypes];
  },
};
export const animalBasicInfoConvertor: TFetchConverter<
  TAnimalBasicInfoUi,
  TAnimalBasicInfo
> = {
  fromDb: data => {
    return {
      birthDate: data.birthDate
        ? formattedDate(data.birthDate, 'MM/DD/YYYY')
        : null,
      breed: data.breed,
      gender: genderEnumConvertor.fromDb(data.gender),
      id: data.id,
      isDeleted: data.isDeleted,
      isHouseTrained: data.isHouseTrained,
      reproduction: data.isReproductive
        ? reproductiveConvertor.fromDb(data.gender)
        : null,
      name: data.name,
      photoGallery: data.photoGallery,
      primaryColor: data.primaryColor || '',
      profilePicture: data.profilePicture,
      secondaryColor1: data.secondaryColor1 || '',
      secondaryColor2: data.secondaryColor2 || '',
      species: data.species,
      status: verificationStatusConverter.fromDb(data.status),
      type: animalTypesConvertor.fromDb(data.type),
      weight: data.weight,
      microchip: data.microchip,
      trainedFor: data.trainedFor,
      isRequiredBecauseOfDisability: data.isRequiredBecauseOfDisability,
    };
  },
};

export const tenantDetailConvertor: TFetchConverter<
  TTenantDetailUi,
  TTenantDetail
> = {
  fromDb: data => {
    return {
      id: data.id,
      photo: data.photo,
      name: data.name,
      email: data.email,
      phone: data.phone,
    };
  },
};

export const residenciesStatusConverter: TConvertor<
  EResidenciesStatusUI,
  EResidenciesStatus
> = {
  fromDb: data => {
    const dataMapper: { [key in EResidenciesStatus]: EResidenciesStatusUI } = {
      [EResidenciesStatus.APPLICANT]: EResidenciesStatusUI.APPLICANT,
      [EResidenciesStatus.RESIDENT]: EResidenciesStatusUI.RESIDENT,
      [EResidenciesStatus.FUTURE]: EResidenciesStatusUI.FUTURE,
      [EResidenciesStatus.MOVED_OUT]: EResidenciesStatusUI.MOVED_OUT,
      [EResidenciesStatus.REJECTED]: EResidenciesStatusUI.REJECTED,
      [EResidenciesStatus.NOTICE]: EResidenciesStatusUI.NOTICE,
    };
    return dataMapper[data];
  },
  toDb: data => {
    const dataMapper: { [key in EResidenciesStatusUI]: EResidenciesStatus } = {
      [EResidenciesStatusUI.APPLICANT]: EResidenciesStatus.APPLICANT,
      [EResidenciesStatusUI.RESIDENT]: EResidenciesStatus.RESIDENT,
      [EResidenciesStatusUI.FUTURE]: EResidenciesStatus.FUTURE,
      [EResidenciesStatusUI.MOVED_OUT]: EResidenciesStatus.MOVED_OUT,
      [EResidenciesStatusUI.REJECTED]: EResidenciesStatus.REJECTED,
      [EResidenciesStatusUI.NOTICE]: EResidenciesStatus.NOTICE,
    };
    return dataMapper[data];
  },
};

export const authorizationStatusConverter: TConvertor<
  EAuthorizationStatusUi,
  EAuthorizationStatus
> = {
  fromDb: data => {
    const statuses: {
      [key in EAuthorizationStatus]: EAuthorizationStatusUi;
    } = {
      [EAuthorizationStatus.AUTHORIZED]: EAuthorizationStatusUi.AUTHORIZED,
    };
    return statuses[data];
  },
  toDb: data => {
    const statuses: {
      [key in EAuthorizationStatusUi]: EAuthorizationStatus;
    } = {
      [EAuthorizationStatusUi.AUTHORIZED]: EAuthorizationStatus.AUTHORIZED,
    };
    return statuses[data];
  },
};
export const notesConvertor: TFetchConverter<TNotesUI, TNotesDB> = {
  fromDb: data => {
    return {
      creationTime: data.creationTime,
      creatorName: data.creatorName,
      id: data.id,
      text: data.text,
    };
  },
};
export const animalApplicationRelatedPropertyManagersConvertor: TFetchConverter<
  TAnimalApplicationRelatedPropertyManagersUi,
  TAnimalApplicationRelatedPropertyManagers
> = {
  fromDb: data => {
    return {
      id: data.id,
      emailAddress: data.emailAddress,
      isLandlord: data.isLandlord,
      name: data.name,
      phoneNumber: data.phoneNumber,
    };
  },
};

export const animalApplicationConvertor: TFetchConverter<
  TAnimalApplicationUi,
  TAnimalApplication
> = {
  fromDb: data => {
    return {
      id: data.id,
      propertyName: data.propertyName,
      unitNumber: data.unitNumber,
      propertyManagementBusinessName: data.propertyManagementBusinessName,
      propertyManagementBusinessLogo: data.propertyManagementBusinessLogo,
      propertyManagerName: data.propertyManagerName,
      leaseStartDate: data.leaseStartDate
        ? formattedDate(data.leaseStartDate)
        : '-',
      leaseToDate: data.leaseToDate ? formattedDate(data.leaseToDate) : null,
      applicationStatus: applicationStatusConvertor.fromDb(
        data.applicationStatus,
      ),
      policyId: data.policyId,
      relatedPropertyManagers: data.relatedPropertyManagers.map(item =>
        animalApplicationRelatedPropertyManagersConvertor.fromDb(item),
      ),
    };
  },
};

export const applicationSADocPacketConvertor: TFetchConverter<
  TApplicationSADocPacketGetUI,
  TApplicationSADocPacketGet
> = {
  fromDb: data => {
    return {
      id: data.id,
      trainerInfo: trainerInfoConvertor.fromDb(data.trainerInfo),
      saLetters: (data.saLetters || []).map(letter =>
        fileDataTypeConverter.fromDb(letter),
      ),
      trainedFor: data.trainedFor,
      startTime: data.trainingStartDate,
      endTime: data.trainingEndDate,
      canEdit: false,
      canModifyDocs:
        docPacketStatusConverter.fromDb(data.status) ===
        EDocPacketStatusUi.NONE,
      fails: failsConvertor.fromDb(data.fails || {}),
      status: docPacketStatusConverter.fromDb(data.status),
    };
  },
};

export const animalVetRecordExpirationDateConvertor: TCreateConverter<
  TExpirationDateType,
  TExpirationDateType
> = {
  toDb: data => ({
    expirable: data.expirable,
    date: `${data.date}Z`,
  }),
};

export const animalVetRecordConvertor: TFetchConverter<
  TApplicationVetRecordsUI,
  TApplicationVetRecords
> = {
  fromDb: data => {
    return {
      records: data.records,
      id: data.id,
      documentFiles: (data.documentFiles || []).map(file =>
        fileDataTypeConverter.fromDb(file),
      ),
      status: vetRecordStatusConverter.fromDb(data.status),
    };
  },
};

export const getAnimalWarningVerificationConverter: TFetchConverter<
  TAnimalWarningVerificationUI,
  TAnimalWarningVerificationDB
> = {
  fromDb: data => ({
    hasNotVerifiedDocument: data.hasNotVerifiedDocument,
  }),
};

export const animalActionConverter: TConvertor<
  EAnimalActionUI,
  EAnimalActionDB
> = {
  fromDb: data => {
    const action: { [key in EAnimalActionDB]: EAnimalActionUI } = {
      [EAnimalActionDB.CONVERT]: EAnimalActionUI.CONVERT,
    };
    return action[data];
  },
  toDb: data => {
    const action: { [key in EAnimalActionUI]: EAnimalActionDB } = {
      [EAnimalActionUI.CONVERT]: EAnimalActionDB.CONVERT,
    };
    return action[data];
  },
};

export const animalBlockedActionConverter: TFetchConverter<
  TBlockedActionUI,
  TBlockedAction
> = {
  fromDb: data => ({
    animalAction: animalActionConverter.fromDb(data.animalAction),
    reason: data.reason,
  }),
};

export const animalConversionStatusConvertor: TFetchConverter<
  EConvertAnimalStatusUI,
  EConvertAnimalStatus
> = {
  fromDb: data => {
    const statuses: {
      [key in EConvertAnimalStatus]: EConvertAnimalStatusUI;
    } = {
      [EConvertAnimalStatus.APPROVED]: EConvertAnimalStatusUI.APPROVED,
      [EConvertAnimalStatus.PENDING]: EConvertAnimalStatusUI.PENDING,
      [EConvertAnimalStatus.REJECTED]: EConvertAnimalStatusUI.REJECTED,
    };
    return statuses[data];
  },
};

export const animalConversionReason: TFetchConverter<
  EConversionReasonUI,
  EConversionReasonDB
> = {
  fromDb: data => {
    const reasons: {
      [key in EConversionReasonDB]: EConversionReasonUI;
    } = {
      [EConversionReasonDB.NOT_RECEIVED_DOCUMENTATION]:
        EConversionReasonUI.NOT_RECEIVED_DOCUMENTATION,
      [EConversionReasonDB.NOT_REQUIRED_TO_ALLEVIATE]:
        EConversionReasonUI.NOT_REQUIRED_TO_ALLEVIATE,
      [EConversionReasonDB.SEVERAL_ATTEMPTS_TO_VERIFY]:
        EConversionReasonUI.SEVERAL_ATTEMPTS_TO_VERIFY,
      [EConversionReasonDB.WAS_NOT_PROVIDED_BY_ITSELF]:
        EConversionReasonUI.WAS_NOT_PROVIDED_BY_ITSELF,
      [EConversionReasonDB.NOT_REQUIRED_FOR_DISABILITY]:
        EConversionReasonUI.NOT_REQUIRED_FOR_DISABILITY,
    };
    return reasons[data];
  },
};

export const animalConversionsConvertor: TFetchConverter<
  TConversionTypeUI,
  TConversionType
> = {
  fromDb: data => {
    const isPredefined = Object.values(EConversionReasonDB).includes(
      data.reason as EConversionReasonDB,
    );
    return {
      trainedFor: data.trainedFor,
      observableDisability: data.observableDisability,
      trainerDocumentInfoNotProvidedReason:
        data.trainerDocumentInfoNotProvidedReason,
      hpDocumentInfoNotProvidedReason: data.hpDocumentInfoNotProvidedReason,
      conversionApprover: data.approveActorName || undefined,
      createdAt: formattedDate(data.createdAt),
      fromType: animalTypesConvertor.fromDb(data.fromType),
      hpDocumentPacket: data.hpDocumentPacket
        ? getHPDocPacketsConvertor.fromDb(data.hpDocumentPacket)
        : null,
      id: data.id,
      isReasonPredefined: isPredefined,
      reason:
        data.reason && isPredefined
          ? animalConversionReason.fromDb(data.reason as EConversionReasonDB)
          : data.reason,
      saDocumentPacket: data.saDocumentPacket
        ? applicationSADocPacketConvertor.fromDb(data.saDocumentPacket)
        : null,
      status: animalConversionStatusConvertor.fromDb(data.status),
      toType: animalTypesConvertor.fromDb(data.toType),
    };
  },
};

export const animalFollowUpItemConvertor: TFetchConverter<
  TFollowUpItemUI,
  TFollowUpItemDB
> = {
  fromDb: data => {
    return {
      authorName: data.authorName,
      createdAt: formattedDate(
        `${data.createdAt}Z`,
        `${defaultDateFormat} HH:mm A`,
      ),
      description: data.description,
      isCompleteItem: data.isCompleteItem,
      propertyManagementName: data.propertyManagementName,
    };
  },
};

export const editingWeightFieldConvertor: TFetchConverter<
  TEditingWeightFieldUi,
  TEditingWeightField
> = {
  fromDb: data => {
    return {
      value: data?.value ? data.value.toString() : '',
      unit: data?.unit || 'LBS',
      canEdit: data.allowedOperations.some(item =>
        [EValueEditingStates.SET, EValueEditingStates.UPDATE].includes(item),
      ),
      canRemove: data.allowedOperations.some(item =>
        [EValueEditingStates.SET, EValueEditingStates.REMOVE].includes(item),
      ),
    };
  },
};

export const readableWeightFieldConvertor: TFetchConverter<
  TEditingWeightFieldUi,
  TEditingWeightField
> = {
  fromDb: data => {
    return {
      value: data?.value ? data.value.toString() : '',
      unit: data?.unit || 'LBS',
      canEdit: false,
      canRemove: false,
    };
  },
};

export const animalDetailsGenderConverter: TFetchConverter<
  TAnimalDetailsGenderFieldUi,
  TAnimalDetailsGenderField
> = {
  fromDb: data => {
    return {
      value: genderEnumConvertor.fromDb(data.value),
      canEdit: data.allowedOperations.some(item =>
        [EValueEditingStates.SET, EValueEditingStates.UPDATE].includes(item),
      ),
      canRemove: data.allowedOperations.some(item =>
        [EValueEditingStates.SET, EValueEditingStates.REMOVE].includes(item),
      ),
    };
  },
};
export const readableDetailsGenderConverter: TFetchConverter<
  TAnimalDetailsGenderFieldUi,
  EGender
> = {
  fromDb: data => {
    return {
      value: genderEnumConvertor.fromDb(data),
      canEdit: false,
      canRemove: false,
    };
  },
};

export const applicationAnimalVetRecordConvertor: TFetchConverter<
  TApplicationVetRecordsUI,
  TApplicationVetRecords
> = {
  fromDb: data => {
    return {
      records: data.records,

      id: data.id,
      documentFiles: (data.documentFiles || []).map(file =>
        fileDataTypeConverter.fromDb(file),
      ),
      status: vetRecordStatusConverter.fromDb(data.status),
    };
  },
};

export const updateVetRecordDocumentsConvertor: TCreateConverter<
  TVetRecordDocumentsUI,
  TVetRecordDocuments
> = {
  toDb: data => ({
    animalId: data.animalId,
    vetRecordId: data.vetRecordId,
    newFiles: (data.docs || []).map(doc => ({
      name: doc.name,
      url: doc.url,
    })),
    removedDocumentsId: data.removedDocumentsId || [],
  }),
};

export const createVetRecordConvertor: TCreateConverter<
  TAnimalVetRecordCreateUi,
  TAnimalVetRecordCreate
> = {
  toDb: data => {
    return {
      animalId: data.animalId,
      expiration: {
        expirable: !data.unexpired,
        date: !data.unexpired ? data.expirationDate : '',
      },
      files: data.files,
      type:
        data.type !== 'Other'
          ? {
              predefined:
                EVetRecordTypeEnum[
                  data.type as keyof typeof EVetRecordTypeEnum
                ],
            }
          : { custom: data.docName },
    };
  },
};
export const deleteVetRecordConvertor: TDeleteConverter<
  TVetRecordUI,
  TVetRecord
> = {
  toDb: data => ({
    animalId: data.animalId,
    vetRecordId: data.vetRecordId,
  }),
};

export const vetRecordExpirationConverter: TCreateConverter<
  TVetRecordExpirationUpdateUI,
  TExpirationDateType
> = {
  toDb(data) {
    return {
      expirable: data.vetRecordDateType !== EVetRecordDateType.UNEXPIRED,
      date:
        data.vetRecordDateType !== EVetRecordDateType.UNEXPIRED
          ? data.expirationDate
          : '',
    };
  },
};
export const updatedVetRecordsConverter: TCreateConverter<
  TVetRecordExpirationUpdateUI,
  TChangedRecords
> = {
  toDb(data) {
    return {
      expiration: vetRecordExpirationConverter.toDb(data),
      id: data.vetRecordId,
    };
  },
};

export const vetRecordExpirationUpdateConverter: TCreateConverter<
  TVetRecordExpirationUpdateUI,
  TVetRecordExpirationUpdate
> = {
  toDb: data => {
    return {
      animalId: data.animalId,
      vetRecordId: data.recordId,
      changedRecords: [updatedVetRecordsConverter.toDb(data)],
    };
  },
};

export const updateAnimalTypeConverter: TCreateConverter<
  TUpdateAnimalTypeUI,
  TUpdateAnimalTypeDB
> = {
  toDb: data => {
    return {
      animalId: data.animalId,
      toType: animalTypesConvertor.toDb(data.toType),
      reason: data.customReason || data.reason,
    };
  },
};

export const followUpAnimalConverter: TCreateConverter<
  TAnimalFollowUpUI,
  TAnimalFollowUpDB
> = {
  toDb: data => {
    return {
      animalId: data.animalId,
      description: data.description,
    };
  },
};

export const approveAnimalTypeConversionConverter: TCreateConverter<
  TApproveAnimalTypeConvUI,
  TApproveAnimalTypeConvDB
> = {
  toDb: data => {
    return {
      animalId: data.animalId,
      animalConversionId: data.animalConversionId,
    };
  },
};

export const rejectAnimalTypeConversionConverter: TCreateConverter<
  TRejectAnimalTypeConvUI,
  TRejectAnimalTypeConvDB
> = {
  toDb: data => {
    return {
      animalId: data.animalId,
      animalConversionId: data.animalConversionId,
      reason: data.reason,
    };
  },
};

export const vetRecordTypeUpdateConverter: TCreateConverter<
  TVetRecordTypeNameUpdateUI,
  TVetRecordTypeNameUpdate
> = {
  toDb: data => {
    return {
      animalId: data.animalId,
      vetRecordId: data.vetRecordId,
      type:
        data.type === 'Rabbies'
          ? {
              predefined:
                EVetRecordTypeEnum[
                  data.type as keyof typeof EVetRecordTypeEnum
                ],
            }
          : { custom: data.type },
    };
  },
};

export const animalResidencyStateConverter: TConvertor<
  EResidencyStateUI,
  EResidencyStateDB
> = {
  fromDb: data => {
    const statuses: {
      [key in EResidencyStateDB]: EResidencyStateUI;
    } = {
      [EResidencyStateDB.NONE]: EResidencyStateUI.NONE,
      [EResidencyStateDB.APPROVED]: EResidencyStateUI.APPROVED,
      [EResidencyStateDB.PENDING]: EResidencyStateUI.PENDING,
      [EResidencyStateDB.FUTURE]: EResidencyStateUI.FUTURE,
    };
    return statuses[data];
  },
  toDb: data => {
    const statuses: {
      [key in EResidencyStateUI]: EResidencyStateDB;
    } = {
      [EResidencyStateUI.NONE]: EResidencyStateDB.NONE,
      [EResidencyStateUI.APPROVED]: EResidencyStateDB.APPROVED,
      [EResidencyStateUI.PENDING]: EResidencyStateDB.PENDING,
      [EResidencyStateUI.FUTURE]: EResidencyStateDB.FUTURE,
    };
    return statuses[data];
  },
};

export const animalStatusConverter: TConvertor<
  EAnimalStatusUI,
  EAnimalStatusDB
> = {
  fromDb: data => {
    const statuses: {
      [key in EAnimalStatusDB]: EAnimalStatusUI;
    } = {
      [EAnimalStatusDB.ARCHIVED]: EAnimalStatusUI.ARCHIVED,
      [EAnimalStatusDB.APPROVED]: EAnimalStatusUI.APPROVED,
      [EAnimalStatusDB.PENDING]: EAnimalStatusUI.PENDING,
      [EAnimalStatusDB.MOVED_IN]: EAnimalStatusUI.MOVED_IN,
      [EAnimalStatusDB.MOVED_OUT]: EAnimalStatusUI.MOVED_OUT,
      [EAnimalStatusDB.NOT_APPROVED]: EAnimalStatusUI.NOT_APPROVED,
      [EAnimalStatusDB.RETRACTED]: EAnimalStatusUI.RETRACTED,
      [EAnimalStatusDB.CONDITIONALLY_MOVED_IN]:
        EAnimalStatusUI.CONDITIONALLY_MOVED_IN,
      [EAnimalStatusDB.CONDITIONALLY_APPROVED]:
        EAnimalStatusUI.CONDITIONALLY_APPROVED,
    };
    return statuses[data];
  },
  toDb: data => {
    const statuses: {
      [key in EAnimalStatusUI]: EAnimalStatusDB;
    } = {
      [EAnimalStatusUI.ARCHIVED]: EAnimalStatusDB.ARCHIVED,
      [EAnimalStatusUI.APPROVED]: EAnimalStatusDB.APPROVED,
      [EAnimalStatusUI.PENDING]: EAnimalStatusDB.PENDING,
      [EAnimalStatusUI.MOVED_IN]: EAnimalStatusDB.MOVED_IN,
      [EAnimalStatusUI.MOVED_OUT]: EAnimalStatusDB.MOVED_OUT,
      [EAnimalStatusUI.NOT_APPROVED]: EAnimalStatusDB.NOT_APPROVED,
      [EAnimalStatusUI.RETRACTED]: EAnimalStatusDB.RETRACTED,
      [EAnimalStatusUI.CONDITIONALLY_APPROVED]:
        EAnimalStatusDB.CONDITIONALLY_APPROVED,
      [EAnimalStatusUI.CONDITIONALLY_MOVED_IN]:
        EAnimalStatusDB.CONDITIONALLY_MOVED_IN,
    };
    return statuses[data];
  },
};
export const animalInfoConverter: TFetchConverter<
  TAnimalInfoUI,
  TAnimalInfoDB
> = {
  fromDb: data => {
    return {
      animalId: data.animalId,
      animalName: data.animalName,
      animalSpecie: data.animalSpecie,
      animalProfilePicture: data.animalProfilePicture,
      animalType: animalTypesConvertor.fromDb(data.animalType),
      animalResidencyState: animalResidencyStateConverter.fromDb(
        data.animalResidencyState,
      ),
      animalStatus: verificationStatusConverter.fromDb(data.animalStatus),
      firstLetters: getFirstLetters(data.animalName),
    };
  },
};

export const tenantInfoConverter: TFetchConverter<
  TTenantInfoUI,
  TTenantInfoDB
> = {
  fromDb: data => {
    return {
      tenantId: data.tenantId,
      tenantName: data.tenantName,
    };
  },
};
export const animalConverter: TAuthConverter<
  { [key: string]: string | string[] } | undefined,
  { [key: string]: string | string[] },
  TAnimalUI,
  TAnimalDB
> = {
  fromDb: data => {
    const {
      animalId,
      animalStatus,
      animalName,
      animalSpecie,
      animalType,
      animalResidencyState,
      animalProfilePicture,
      tenantId,
      tenantName,
      propertyManagementName,
      propertyManagementId,
      propertyManagementLogo,
      propertyId,
      propertyName,
      creationTime,
      lastFollowedUpTime,
    } = data;
    return {
      animalInfo: animalInfoConverter.fromDb({
        animalId,
        animalStatus,
        animalName,
        animalSpecie,
        animalType,
        animalResidencyState,
        animalProfilePicture,
      }),
      tenantInfo: tenantInfoConverter.fromDb({ tenantId, tenantName }),
      propertyManagement: propertyManagementInfoConverter.fromDb({
        propertyManagementName,
        propertyManagementId,
        propertyManagementLogo,
      }),
      read: data.read,
      propertyInfo: propertyInfoConverter.fromDb({ propertyId, propertyName }),
      creationTime: formattedDate(creationTime),
      lastFollowedUpTime: lastFollowedUpTime
        ? formattedDate(
            `${lastFollowedUpTime}Z`,
            `${defaultDateFormat} HH:mm A`,
          )
        : 'N/A',
    };
  },
  toDb: filter => {
    const filtersObj: { [key: string]: string | string[] } = {};
    if (!filter) {
      return {};
    }
    Object.keys(filter).forEach(item => {
      if (
        (Array.isArray(filter[item]) && filter[item].length) ||
        (!Array.isArray(filter[item]) && !!filter[item])
      ) {
        filtersObj[item] = filter[item];
      }
    });
    return filtersObj;
  },
};

export const notBeingChargedReasonConverter: TConvertor<
  ENotBeingChargedReasonUI,
  ENotBeingChargedReasonDB
> = {
  fromDb: data => {
    const statuses: {
      [key in ENotBeingChargedReasonDB]: ENotBeingChargedReasonUI;
    } = {
      [ENotBeingChargedReasonDB.PET_FEE]: ENotBeingChargedReasonUI.PET_FEE,
      [ENotBeingChargedReasonDB.PET_RENT]: ENotBeingChargedReasonUI.PET_RENT,
    };
    return statuses[data];
  },
  toDb: data => {
    const statuses: {
      [key in ENotBeingChargedReasonUI]: ENotBeingChargedReasonDB;
    } = {
      [ENotBeingChargedReasonUI.PET_FEE]: ENotBeingChargedReasonDB.PET_FEE,
      [ENotBeingChargedReasonUI.PET_RENT]: ENotBeingChargedReasonDB.PET_RENT,
    };
    return statuses[data];
  },
};

export const updatePetChargingStatusConverter: TCreateConverter<
  TChargingStatusUpdateUI,
  TChargingStatusUpdateDB
> = {
  toDb: data => {
    return {
      tenantResidencyId: data.tenantResidencyId,
      animalResidencyId: data.animalResidencyId,
      chargingStatus: animalChargingStatusConverter.toDb(data.chargingStatus),
      ...(data.reason && { reason: data.reason }),
      ...(data.notBeingChargedReason && {
        notBeingChargedReason: notBeingChargedReasonConverter.toDb(
          data.notBeingChargedReason,
        ),
      }),
      ...(data.shouldTagAnimalAsPNC !== undefined && {
        shouldTagAnimalAsPNC: data.shouldTagAnimalAsPNC,
      }),
    };
  },
};

export const markAsPNCConverter: TCreateConverter<TMarkAsPNCUI, TMarkAsPNCDB> =
  {
    toDb: data => {
      return {
        tenantResidencyId: data.tenantResidencyId,
        animalResidencyId: data.animalResidencyId,
      };
    },
  };

export const removeApplicantDiscoveredConverter: TCreateConverter<
  TRemoveApplicantDiscoveredUI,
  TRemoveApplicantDiscoveredDB
> = {
  toDb: data => {
    return {
      tenantResidencyId: data.tenantResidencyId,
      applicationId: data.applicationId,
    };
  },
};

export const markAsDiscoveredApplicantAnimalConverter: TCreateConverter<
  TMarkAsDiscoverdApplicantUI,
  TMarkAsDiscoverdApplicantDB
> = {
  toDb: data => {
    return {
      tenantResidencyId: data.tenantResidencyId,
      applicationId: data.applicationId,
      discoveredTagReason: data.discoveredTagReason,
    };
  },
};

export const markAsDiscoveredAnimalConverter: TCreateConverter<
  TMarkAsDiscoverdUI,
  TMarkAsDiscoverdDB
> = {
  toDb: data => {
    return {
      tenantResidencyId: data.tenantResidencyId,
      animalResidencyId: data.animalResidencyId,
      discoveredTagReason: data.discoveredTagReason,
    };
  },
};

export const animalResidencyConverter: TFetchConverter<
  TAnimalResidencyUI,
  TAnimalResidencyDB
> = {
  fromDb: data => {
    return {
      id: data.id,
      chargingStatus: data.chargingStatus
        ? animalChargingStatusConverter.fromDb(data.chargingStatus)
        : null,
      chargingStatusInfo: data.chargingStatusInfo
        ? statusInfoWithResourceConverter.fromDb(data.chargingStatusInfo)
        : null,
      notBeingChargedReason: data.notBeingChargedReason || '',
      chargingStatusChangeReason: data.chargingStatusChangeReason || '',
      tags: (data.tags || []).map(item => tagConverter.fromDb(item)),
      canTagKnown: data.canTagKnown,
      status: animalStatusConverter.fromDb(data.status),
      discoveredTagReason: data.discoveredTagReason,
      policySignedBy: data.policySignedBy,
      policyDateSigned: data.policyDateSigned
        ? formattedDate(data.policyDateSigned)
        : 'N/A',
      policyName: data.policyName,
      policyContent: data.policyContent
        ? URL.createObjectURL(b64toBlob(data.policyContent, 'text/html'))
        : null,
      policyId: data.policyId,
      statusChangeInfo: data.statusChangeInfo
        ? statusChangeInfoConverter.fromDb(data.statusChangeInfo)
        : null,
      missingMoveInDateReason: data.missingMoveInDateReason,
      moveInDate: data.moveInDate ? formattedDate(data.moveInDate) : null,
    };
  },
};

export const residenciesConvertor: TFetchConverter<
  TResidenciesUi,
  TResidencies
> = {
  fromDb: data => {
    return {
      animalResidency: data.animalResidency
        ? animalResidencyConverter.fromDb(data.animalResidency)
        : null,
      tags: data.tags.map(item => tenantTagConverter.fromDb(item)),
      id: data.id,
      authorizationStatus: authorizationStatusConverter.fromDb(
        data.authorizationStatus,
      ),
      propertyName: data.propertyName,
      unitNumber: data.unitNumber,
      notes: data.notes.map(note => notesConvertor.fromDb(note)),
      propertyManagementBusinessName: data.propertyManagementBusinessName,
      propertyManagementBusinessLogo: data.propertyManagementBusinessLogo,
      leaseStartDate: data.leaseStartDate
        ? formattedDate(data.leaseStartDate)
        : '-',
      leaseToDate: data.leaseToDate ? formattedDate(data.leaseToDate) : '-',
      policyId: data.policyId,
      status: residenciesStatusConverter.fromDb(data.status),
      isExpired: data.isExpired,
      propertyManagers: data.propertyManagers,
      statusChangeInfo: data.statusChangeInfo
        ? statusInfoWithResourceConverter.fromDb(data.statusChangeInfo)
        : null,
    };
  },
};

export const getAnimalConverter: TFetchConverter<
  TAnimalDetailUi,
  TAnimalDetail
> = {
  fromDb: data => {
    return {
      hasObservableDisability: data.hasObservableDisability,
      animal: animalBasicInfoConvertor.fromDb(data.animal),
      tenant: tenantDetailConvertor.fromDb(data.tenant),
      residencies:
        (data.residencies || []).map(item =>
          residenciesConvertor.fromDb(item),
        ) || [],
      applications: (data.applications || []).map(item =>
        animalApplicationConvertor.fromDb(item),
      ),
      vetRecords: (data.vetRecords || []).map(item =>
        animalVetRecordConvertor.fromDb(item),
      ),
      hpDocPackets: (data.hpDocPackets || []).map(packet => ({
        ...getHPDocPacketsConvertor.fromDb(packet),
      })),
      saDocuments: (data.saDocuments || []).map(item =>
        applicationSADocPacketConvertor.fromDb(item),
      ),
      conversions: (data.conversions || []).map(item =>
        animalConversionsConvertor.fromDb(item),
      ),
      followUpItems: (data.followUpItems || []).map(item =>
        animalFollowUpItemConvertor.fromDb(item),
      ),
      isFollowUpCompleted: data.isFollowUpCompleted,
      blockedActions: (data.blockedActions || [])?.map(action =>
        animalBlockedActionConverter.fromDb(action),
      ),
      statusChangeInfo: data.statusChangeInfo
        ? statusChangeInfoConverter.fromDb(data.statusChangeInfo)
        : null,
      missingNameReason: data.missingNameReason,
      missingTypeReason: data.missingTypeReason,
    };
  },
};
